import React from "react";
import { useSelector } from "react-redux";

const UserProfile = () => {

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">

    </div>
  )
};

export default UserProfile;
